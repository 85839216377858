import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _412918bf = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _01f9cf38 = () => interopDefault(import('../pages/analytics.vue' /* webpackChunkName: "pages/analytics" */))
const _9967e88a = () => interopDefault(import('../pages/analytics/index.vue' /* webpackChunkName: "pages/analytics/index" */))
const _ea96ca7a = () => interopDefault(import('../pages/analytics/pools/index.vue' /* webpackChunkName: "pages/analytics/pools/index" */))
const _a61cf9cc = () => interopDefault(import('../pages/analytics/spots.vue' /* webpackChunkName: "pages/analytics/spots" */))
const _39db4ba5 = () => interopDefault(import('../pages/analytics/wax-tools.vue' /* webpackChunkName: "pages/analytics/wax-tools" */))
const _212071eb = () => interopDefault(import('../pages/analytics/pools/_id.vue' /* webpackChunkName: "pages/analytics/pools/_id" */))
const _7645812c = () => interopDefault(import('../pages/analytics/tokens/_id.vue' /* webpackChunkName: "pages/analytics/tokens/_id" */))
const _4972d126 = () => interopDefault(import('../pages/analytics/_id/index.vue' /* webpackChunkName: "pages/analytics/_id/index" */))
const _93560ee6 = () => interopDefault(import('../pages/analytics/_id/_pair.vue' /* webpackChunkName: "pages/analytics/_id/_pair" */))
const _16b0bbc7 = () => interopDefault(import('../pages/bridge.vue' /* webpackChunkName: "pages/bridge" */))
const _9503e6b0 = () => interopDefault(import('../pages/bridge_new.vue' /* webpackChunkName: "pages/bridge_new" */))
const _96af15aa = () => interopDefault(import('../pages/bridge_new/index.vue' /* webpackChunkName: "pages/bridge_new/index" */))
const _303bd3ad = () => interopDefault(import('../pages/bridge_new/history.vue' /* webpackChunkName: "pages/bridge_new/history" */))
const _75b430b4 = () => interopDefault(import('../pages/buy-crypto.vue' /* webpackChunkName: "pages/buy-crypto" */))
const _69c22b67 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _244d85d1 = () => interopDefault(import('../pages/chart-widget.vue' /* webpackChunkName: "pages/chart-widget" */))
const _60e0f384 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _fbb63a0e = () => interopDefault(import('../pages/docs.vue' /* webpackChunkName: "pages/docs" */))
const _64657a12 = () => interopDefault(import('../pages/farm/index.vue' /* webpackChunkName: "pages/farm/index" */))
const _44529d6b = () => interopDefault(import('../pages/fees.vue' /* webpackChunkName: "pages/fees" */))
const _350c1fda = () => interopDefault(import('../pages/logos.vue' /* webpackChunkName: "pages/logos" */))
const _19dcf92c = () => interopDefault(import('../pages/markets/index.vue' /* webpackChunkName: "pages/markets/index" */))
const _31fbfff9 = () => interopDefault(import('../pages/new_market.vue' /* webpackChunkName: "pages/new_market" */))
const _254446ee = () => interopDefault(import('../pages/nft-market/index.vue' /* webpackChunkName: "pages/nft-market/index" */))
const _773a7cd3 = () => interopDefault(import('../pages/otc/index.vue' /* webpackChunkName: "pages/otc/index" */))
const _01f92f9f = () => interopDefault(import('../pages/positions/index.vue' /* webpackChunkName: "pages/positions/index" */))
const _3c58db69 = () => interopDefault(import('../pages/staking.vue' /* webpackChunkName: "pages/staking" */))
const _01352c14 = () => interopDefault(import('../pages/swap/index.vue' /* webpackChunkName: "pages/swap/index" */))
const _8121de20 = () => interopDefault(import('../pages/swap-widget.vue' /* webpackChunkName: "pages/swap-widget" */))
const _0a9501d0 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _45db2c9c = () => interopDefault(import('../pages/test-page.vue' /* webpackChunkName: "pages/test-page" */))
const _bd5afdce = () => interopDefault(import('../pages/trade/index.vue' /* webpackChunkName: "pages/trade/index" */))
const _564a9a44 = () => interopDefault(import('../pages/trade/index/_id.vue' /* webpackChunkName: "pages/trade/index/_id" */))
const _948de3d4 = () => interopDefault(import('../pages/trading/index.vue' /* webpackChunkName: "pages/trading/index" */))
const _246ab437 = () => interopDefault(import('../pages/wallet.vue' /* webpackChunkName: "pages/wallet" */))
const _0052150c = () => interopDefault(import('../pages/wallet/index.vue' /* webpackChunkName: "pages/wallet/index" */))
const _22dc23b6 = () => interopDefault(import('../pages/wallet/farms.vue' /* webpackChunkName: "pages/wallet/farms" */))
const _aecb0e86 = () => interopDefault(import('../pages/wallet/friends.vue' /* webpackChunkName: "pages/wallet/friends" */))
const _69cb7c3c = () => interopDefault(import('../pages/wallet/history.vue' /* webpackChunkName: "pages/wallet/history" */))
const _611a63f2 = () => interopDefault(import('../pages/wallet/liquidity_pools.vue' /* webpackChunkName: "pages/wallet/liquidity_pools" */))
const _207c4f82 = () => interopDefault(import('../pages/wallet/nfts.vue' /* webpackChunkName: "pages/wallet/nfts" */))
const _951618fc = () => interopDefault(import('../pages/wallet/nfts/index.vue' /* webpackChunkName: "pages/wallet/nfts/index" */))
const _2f934d10 = () => interopDefault(import('../pages/wallet/nfts/auctions.vue' /* webpackChunkName: "pages/wallet/nfts/auctions" */))
const _179873eb = () => interopDefault(import('../pages/wallet/nfts/bought.vue' /* webpackChunkName: "pages/wallet/nfts/bought" */))
const _e5881d22 = () => interopDefault(import('../pages/wallet/nfts/listings.vue' /* webpackChunkName: "pages/wallet/nfts/listings" */))
const _1d4337d8 = () => interopDefault(import('../pages/wallet/nfts/sets/index.vue' /* webpackChunkName: "pages/wallet/nfts/sets/index" */))
const _18286d58 = () => interopDefault(import('../pages/wallet/nfts/sold.vue' /* webpackChunkName: "pages/wallet/nfts/sold" */))
const _4d489c55 = () => interopDefault(import('../pages/wallet/nfts/sortingOptions.js' /* webpackChunkName: "pages/wallet/nfts/sortingOptions" */))
const _2f9e5b7c = () => interopDefault(import('../pages/wallet/nfts/sets/_id.vue' /* webpackChunkName: "pages/wallet/nfts/sets/_id" */))
const _f9f1b108 = () => interopDefault(import('../pages/wallet/nfts/trading/_id.vue' /* webpackChunkName: "pages/wallet/nfts/trading/_id" */))
const _3b5e75dc = () => interopDefault(import('../pages/wallet/positions.vue' /* webpackChunkName: "pages/wallet/positions" */))
const _45b0bd66 = () => interopDefault(import('../pages/wallet/resources.vue' /* webpackChunkName: "pages/wallet/resources" */))
const _4f912bbc = () => interopDefault(import('../pages/wallet/tokens.vue' /* webpackChunkName: "pages/wallet/tokens" */))
const _f9de1474 = () => interopDefault(import('../pages/wallet-inventory.vue' /* webpackChunkName: "pages/wallet-inventory" */))
const _4ba38fc9 = () => interopDefault(import('../pages/wallet-inventory/index.vue' /* webpackChunkName: "pages/wallet-inventory/index" */))
const _164a826a = () => interopDefault(import('../pages/wallet-inventory/history.vue' /* webpackChunkName: "pages/wallet-inventory/history" */))
const _37fcb0fe = () => interopDefault(import('../pages/wallet-inventory/liquidity_pools.vue' /* webpackChunkName: "pages/wallet-inventory/liquidity_pools" */))
const _3a477293 = () => interopDefault(import('../pages/wallet-inventory/nfts/index.vue' /* webpackChunkName: "pages/wallet-inventory/nfts/index" */))
const _32ccc03e = () => interopDefault(import('../pages/wallet-inventory/positions.vue' /* webpackChunkName: "pages/wallet-inventory/positions" */))
const _3d1f07c8 = () => interopDefault(import('../pages/wallet-inventory/resources.vue' /* webpackChunkName: "pages/wallet-inventory/resources" */))
const _3c344fea = () => interopDefault(import('../pages/wallet-inventory/trade-offer.vue' /* webpackChunkName: "pages/wallet-inventory/trade-offer" */))
const _138f66a7 = () => interopDefault(import('../pages/farm/create.vue' /* webpackChunkName: "pages/farm/create" */))
const _0a49f357 = () => interopDefault(import('../pages/nft-market/collectionview.vue' /* webpackChunkName: "pages/nft-market/collectionview" */))
const _7f4c07da = () => interopDefault(import('../pages/nft-market/create/index.vue' /* webpackChunkName: "pages/nft-market/create/index" */))
const _298eb19e = () => interopDefault(import('../pages/nft-market/createcollection/index.vue' /* webpackChunkName: "pages/nft-market/createcollection/index" */))
const _56acec5c = () => interopDefault(import('../pages/nft-market/createnft.vue' /* webpackChunkName: "pages/nft-market/createnft" */))
const _6cb6fb2e = () => interopDefault(import('../pages/nft-market/creatingschema.vue' /* webpackChunkName: "pages/nft-market/creatingschema" */))
const _24b59d36 = () => interopDefault(import('../pages/nft-market/newtemplate.vue' /* webpackChunkName: "pages/nft-market/newtemplate" */))
const _200b20d6 = () => interopDefault(import('../pages/nft-market/nft-marketplace.vue' /* webpackChunkName: "pages/nft-market/nft-marketplace" */))
const _008cbe59 = () => interopDefault(import('../pages/nft-market/nft-marketplace/auctions.vue' /* webpackChunkName: "pages/nft-market/nft-marketplace/auctions" */))
const _2ac4bbda = () => interopDefault(import('../pages/nft-market/nft-marketplace/sales.vue' /* webpackChunkName: "pages/nft-market/nft-marketplace/sales" */))
const _4c50bf01 = () => interopDefault(import('../pages/nft-market/nftburnable.vue' /* webpackChunkName: "pages/nft-market/nftburnable" */))
const _02717497 = () => interopDefault(import('../pages/nft-market/nftexplorer.vue' /* webpackChunkName: "pages/nft-market/nftexplorer" */))
const _7b276be4 = () => interopDefault(import('../pages/nft-market/nftexplorer/accounts.vue' /* webpackChunkName: "pages/nft-market/nftexplorer/accounts" */))
const _61f949ae = () => interopDefault(import('../pages/nft-market/nftexplorer/all.vue' /* webpackChunkName: "pages/nft-market/nftexplorer/all" */))
const _d32361aa = () => interopDefault(import('../pages/nft-market/nftexplorer/assets.vue' /* webpackChunkName: "pages/nft-market/nftexplorer/assets" */))
const _51bc7d1d = () => interopDefault(import('../pages/nft-market/nftexplorer/collections.vue' /* webpackChunkName: "pages/nft-market/nftexplorer/collections" */))
const _fb3a767e = () => interopDefault(import('../pages/nft-market/nftexplorer/templates.vue' /* webpackChunkName: "pages/nft-market/nftexplorer/templates" */))
const _11132e2f = () => interopDefault(import('../pages/nft-market/schematemplate.vue' /* webpackChunkName: "pages/nft-market/schematemplate" */))
const _5289b54c = () => interopDefault(import('../pages/nft-market/schemaview.vue' /* webpackChunkName: "pages/nft-market/schemaview" */))
const _43ecac0c = () => interopDefault(import('../pages/nft-market/tradeoffer.vue' /* webpackChunkName: "pages/nft-market/tradeoffer" */))
const _6c47c121 = () => interopDefault(import('../pages/positions/history.vue' /* webpackChunkName: "pages/positions/history" */))
const _5b437370 = () => interopDefault(import('../pages/positions/new/index.vue' /* webpackChunkName: "pages/positions/new/index" */))
const _cef5c900 = () => interopDefault(import('../pages/positions/new/index/_.vue' /* webpackChunkName: "pages/positions/new/index/_" */))
const _75dd3d2d = () => interopDefault(import('../pages/trading/buy-offers/index.vue' /* webpackChunkName: "pages/trading/buy-offers/index" */))
const _3396046a = () => interopDefault(import('../pages/trading/gift-links/index.vue' /* webpackChunkName: "pages/trading/gift-links/index" */))
const _1bfea25b = () => interopDefault(import('../pages/trading/history/index.vue' /* webpackChunkName: "pages/trading/history/index" */))
const _862b85e2 = () => interopDefault(import('../pages/trading/trade-offers/index.vue' /* webpackChunkName: "pages/trading/trade-offers/index" */))
const _b5c30b7e = () => interopDefault(import('../pages/nft-market/createcollection/_collection_name/index.vue' /* webpackChunkName: "pages/nft-market/createcollection/_collection_name/index" */))
const _4b0cf2d8 = () => interopDefault(import('../pages/nft-market/order/_id/index.vue' /* webpackChunkName: "pages/nft-market/order/_id/index" */))
const _a9ce6306 = () => interopDefault(import('../pages/otc/order/_id/index.vue' /* webpackChunkName: "pages/otc/order/_id/index" */))
const _3c02a6d5 = () => interopDefault(import('../pages/trading/buy-offers/_id.vue' /* webpackChunkName: "pages/trading/buy-offers/_id" */))
const _79edf6f3 = () => interopDefault(import('../pages/trading/gift-links/_id.vue' /* webpackChunkName: "pages/trading/gift-links/_id" */))
const _0bf77f83 = () => interopDefault(import('../pages/trading/history/_id.vue' /* webpackChunkName: "pages/trading/history/_id" */))
const _1e9b9192 = () => interopDefault(import('../pages/trading/trade-offers/_id.vue' /* webpackChunkName: "pages/trading/trade-offers/_id" */))
const _5d1b3dfe = () => interopDefault(import('../pages/nft-market/createcollection/_collection_name/createschema.vue' /* webpackChunkName: "pages/nft-market/createcollection/_collection_name/createschema" */))
const _04021da6 = () => interopDefault(import('../pages/nft-market/createcollection/_collection_name/_shema/_schema_name/index.vue' /* webpackChunkName: "pages/nft-market/createcollection/_collection_name/_shema/_schema_name/index" */))
const _742c151c = () => interopDefault(import('../pages/nft-market/createcollection/_collection_name/_shema/_schema_name/createTemplate.vue' /* webpackChunkName: "pages/nft-market/createcollection/_collection_name/_shema/_schema_name/createTemplate" */))
const _23d2170a = () => interopDefault(import('../pages/account/_id.vue' /* webpackChunkName: "pages/account/_id" */))
const _1bb6414c = () => interopDefault(import('../pages/account/_id/nfts.vue' /* webpackChunkName: "pages/account/_id/nfts" */))
const _85807aba = () => interopDefault(import('../pages/account/_id/nfts/auctions.vue' /* webpackChunkName: "pages/account/_id/nfts/auctions" */))
const _1a099b84 = () => interopDefault(import('../pages/account/_id/nfts/bought.vue' /* webpackChunkName: "pages/account/_id/nfts/bought" */))
const _098e7fd9 = () => interopDefault(import('../pages/account/_id/nfts/inventory.vue' /* webpackChunkName: "pages/account/_id/nfts/inventory" */))
const _1ae86702 = () => interopDefault(import('../pages/account/_id/nfts/listings.vue' /* webpackChunkName: "pages/account/_id/nfts/listings" */))
const _74787e32 = () => interopDefault(import('../pages/account/_id/nfts/sets/index.vue' /* webpackChunkName: "pages/account/_id/nfts/sets/index" */))
const _63800067 = () => interopDefault(import('../pages/account/_id/nfts/sold.vue' /* webpackChunkName: "pages/account/_id/nfts/sold" */))
const _906b5e8e = () => interopDefault(import('../pages/account/_id/nfts/sets/_setid.vue' /* webpackChunkName: "pages/account/_id/nfts/sets/_setid" */))
const _da55499c = () => interopDefault(import('../pages/account/_id/tokens/index.vue' /* webpackChunkName: "pages/account/_id/tokens/index" */))
const _012d8126 = () => interopDefault(import('../pages/account/_id/transactions/index.vue' /* webpackChunkName: "pages/account/_id/transactions/index" */))
const _56c1ba8f = () => interopDefault(import('../pages/nfts/_asset_id.vue' /* webpackChunkName: "pages/nfts/_asset_id" */))
const _c206e472 = () => interopDefault(import('../pages/positions/_id.vue' /* webpackChunkName: "pages/positions/_id" */))
const _a9d243a0 = () => interopDefault(import('../pages/sale/_id.vue' /* webpackChunkName: "pages/sale/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _412918bf,
    name: "about___en"
  }, {
    path: "/analytics",
    component: _01f9cf38,
    children: [{
      path: "",
      component: _9967e88a,
      name: "analytics___en"
    }, {
      path: "pools",
      component: _ea96ca7a,
      name: "analytics-pools___en"
    }, {
      path: "spots",
      component: _a61cf9cc,
      name: "analytics-spots___en"
    }, {
      path: "wax-tools",
      component: _39db4ba5,
      name: "analytics-wax-tools___en"
    }, {
      path: "pools/:id",
      component: _212071eb,
      name: "analytics-pools-id___en"
    }, {
      path: "tokens/:id?",
      component: _7645812c,
      name: "analytics-tokens-id___en"
    }, {
      path: ":id",
      component: _4972d126,
      name: "analytics-id___en"
    }, {
      path: ":id/:pair",
      component: _93560ee6,
      name: "analytics-id-pair___en"
    }]
  }, {
    path: "/bridge",
    component: _16b0bbc7,
    name: "bridge___en"
  }, {
    path: "/bridge_new",
    component: _9503e6b0,
    children: [{
      path: "",
      component: _96af15aa,
      name: "bridge_new___en"
    }, {
      path: "history",
      component: _303bd3ad,
      name: "bridge_new-history___en"
    }]
  }, {
    path: "/buy-crypto",
    component: _75b430b4,
    name: "buy-crypto___en"
  }, {
    path: "/careers",
    component: _69c22b67,
    name: "careers___en"
  }, {
    path: "/chart-widget",
    component: _244d85d1,
    name: "chart-widget___en"
  }, {
    path: "/cn",
    component: _60e0f384,
    name: "index___cn"
  }, {
    path: "/docs",
    component: _fbb63a0e,
    name: "docs___en"
  }, {
    path: "/farm",
    component: _64657a12,
    name: "farm___en"
  }, {
    path: "/fees",
    component: _44529d6b,
    name: "fees___en"
  }, {
    path: "/it",
    component: _60e0f384,
    name: "index___it"
  }, {
    path: "/logos",
    component: _350c1fda,
    name: "logos___en"
  }, {
    path: "/markets",
    component: _19dcf92c,
    name: "markets___en"
  }, {
    path: "/new_market",
    component: _31fbfff9,
    name: "new_market___en"
  }, {
    path: "/nft-market",
    component: _254446ee,
    name: "nft-market___en"
  }, {
    path: "/otc",
    component: _773a7cd3,
    name: "otc___en"
  }, {
    path: "/ph",
    component: _60e0f384,
    name: "index___ph"
  }, {
    path: "/positions",
    component: _01f92f9f,
    name: "positions___en"
  }, {
    path: "/ru",
    component: _60e0f384,
    name: "index___ru"
  }, {
    path: "/staking",
    component: _3c58db69,
    name: "staking___en"
  }, {
    path: "/swap",
    component: _01352c14,
    name: "swap___en"
  }, {
    path: "/swap-widget",
    component: _8121de20,
    name: "swap-widget___en"
  }, {
    path: "/test",
    component: _0a9501d0,
    name: "test___en"
  }, {
    path: "/test-page",
    component: _45db2c9c,
    name: "test-page___en"
  }, {
    path: "/trade",
    component: _bd5afdce,
    name: "trade___en",
    children: [{
      path: ":id?",
      component: _564a9a44,
      name: "trade-index-id___en"
    }]
  }, {
    path: "/trading",
    component: _948de3d4,
    name: "trading___en"
  }, {
    path: "/ua",
    component: _60e0f384,
    name: "index___ua"
  }, {
    path: "/wallet",
    component: _246ab437,
    children: [{
      path: "",
      component: _0052150c,
      name: "wallet___en"
    }, {
      path: "farms",
      component: _22dc23b6,
      name: "wallet-farms___en"
    }, {
      path: "friends",
      component: _aecb0e86,
      name: "wallet-friends___en"
    }, {
      path: "history",
      component: _69cb7c3c,
      name: "wallet-history___en"
    }, {
      path: "liquidity_pools",
      component: _611a63f2,
      name: "wallet-liquidity_pools___en"
    }, {
      path: "nfts",
      component: _207c4f82,
      children: [{
        path: "",
        component: _951618fc,
        name: "wallet-nfts___en"
      }, {
        path: "auctions",
        component: _2f934d10,
        name: "wallet-nfts-auctions___en"
      }, {
        path: "bought",
        component: _179873eb,
        name: "wallet-nfts-bought___en"
      }, {
        path: "listings",
        component: _e5881d22,
        name: "wallet-nfts-listings___en"
      }, {
        path: "sets",
        component: _1d4337d8,
        name: "wallet-nfts-sets___en"
      }, {
        path: "sold",
        component: _18286d58,
        name: "wallet-nfts-sold___en"
      }, {
        path: "sortingOptions",
        component: _4d489c55,
        name: "wallet-nfts-sortingOptions___en"
      }, {
        path: "sets/:id",
        component: _2f9e5b7c,
        name: "wallet-nfts-sets-id___en"
      }, {
        path: "trading/:id?",
        component: _f9f1b108,
        name: "wallet-nfts-trading-id___en"
      }]
    }, {
      path: "positions",
      component: _3b5e75dc,
      name: "wallet-positions___en"
    }, {
      path: "resources",
      component: _45b0bd66,
      name: "wallet-resources___en"
    }, {
      path: "tokens",
      component: _4f912bbc,
      name: "wallet-tokens___en"
    }]
  }, {
    path: "/wallet-inventory",
    component: _f9de1474,
    children: [{
      path: "",
      component: _4ba38fc9,
      name: "wallet-inventory___en"
    }, {
      path: "history",
      component: _164a826a,
      name: "wallet-inventory-history___en"
    }, {
      path: "liquidity_pools",
      component: _37fcb0fe,
      name: "wallet-inventory-liquidity_pools___en"
    }, {
      path: "nfts",
      component: _3a477293,
      name: "wallet-inventory-nfts___en"
    }, {
      path: "positions",
      component: _32ccc03e,
      name: "wallet-inventory-positions___en"
    }, {
      path: "resources",
      component: _3d1f07c8,
      name: "wallet-inventory-resources___en"
    }, {
      path: "trade-offer",
      component: _3c344fea,
      name: "wallet-inventory-trade-offer___en"
    }]
  }, {
    path: "/cn/about",
    component: _412918bf,
    name: "about___cn"
  }, {
    path: "/cn/analytics",
    component: _01f9cf38,
    children: [{
      path: "",
      component: _9967e88a,
      name: "analytics___cn"
    }, {
      path: "pools",
      component: _ea96ca7a,
      name: "analytics-pools___cn"
    }, {
      path: "spots",
      component: _a61cf9cc,
      name: "analytics-spots___cn"
    }, {
      path: "wax-tools",
      component: _39db4ba5,
      name: "analytics-wax-tools___cn"
    }, {
      path: "pools/:id",
      component: _212071eb,
      name: "analytics-pools-id___cn"
    }, {
      path: "tokens/:id?",
      component: _7645812c,
      name: "analytics-tokens-id___cn"
    }, {
      path: ":id",
      component: _4972d126,
      name: "analytics-id___cn"
    }, {
      path: ":id/:pair",
      component: _93560ee6,
      name: "analytics-id-pair___cn"
    }]
  }, {
    path: "/cn/bridge",
    component: _16b0bbc7,
    name: "bridge___cn"
  }, {
    path: "/cn/bridge_new",
    component: _9503e6b0,
    children: [{
      path: "",
      component: _96af15aa,
      name: "bridge_new___cn"
    }, {
      path: "history",
      component: _303bd3ad,
      name: "bridge_new-history___cn"
    }]
  }, {
    path: "/cn/buy-crypto",
    component: _75b430b4,
    name: "buy-crypto___cn"
  }, {
    path: "/cn/careers",
    component: _69c22b67,
    name: "careers___cn"
  }, {
    path: "/cn/chart-widget",
    component: _244d85d1,
    name: "chart-widget___cn"
  }, {
    path: "/cn/docs",
    component: _fbb63a0e,
    name: "docs___cn"
  }, {
    path: "/cn/farm",
    component: _64657a12,
    name: "farm___cn"
  }, {
    path: "/cn/fees",
    component: _44529d6b,
    name: "fees___cn"
  }, {
    path: "/cn/logos",
    component: _350c1fda,
    name: "logos___cn"
  }, {
    path: "/cn/markets",
    component: _19dcf92c,
    name: "markets___cn"
  }, {
    path: "/cn/new_market",
    component: _31fbfff9,
    name: "new_market___cn"
  }, {
    path: "/cn/nft-market",
    component: _254446ee,
    name: "nft-market___cn"
  }, {
    path: "/cn/otc",
    component: _773a7cd3,
    name: "otc___cn"
  }, {
    path: "/cn/positions",
    component: _01f92f9f,
    name: "positions___cn"
  }, {
    path: "/cn/staking",
    component: _3c58db69,
    name: "staking___cn"
  }, {
    path: "/cn/swap",
    component: _01352c14,
    name: "swap___cn"
  }, {
    path: "/cn/swap-widget",
    component: _8121de20,
    name: "swap-widget___cn"
  }, {
    path: "/cn/test",
    component: _0a9501d0,
    name: "test___cn"
  }, {
    path: "/cn/test-page",
    component: _45db2c9c,
    name: "test-page___cn"
  }, {
    path: "/cn/trade",
    component: _bd5afdce,
    name: "trade___cn",
    children: [{
      path: ":id?",
      component: _564a9a44,
      name: "trade-index-id___cn"
    }]
  }, {
    path: "/cn/trading",
    component: _948de3d4,
    name: "trading___cn"
  }, {
    path: "/cn/wallet",
    component: _246ab437,
    children: [{
      path: "",
      component: _0052150c,
      name: "wallet___cn"
    }, {
      path: "farms",
      component: _22dc23b6,
      name: "wallet-farms___cn"
    }, {
      path: "friends",
      component: _aecb0e86,
      name: "wallet-friends___cn"
    }, {
      path: "history",
      component: _69cb7c3c,
      name: "wallet-history___cn"
    }, {
      path: "liquidity_pools",
      component: _611a63f2,
      name: "wallet-liquidity_pools___cn"
    }, {
      path: "nfts",
      component: _207c4f82,
      children: [{
        path: "",
        component: _951618fc,
        name: "wallet-nfts___cn"
      }, {
        path: "auctions",
        component: _2f934d10,
        name: "wallet-nfts-auctions___cn"
      }, {
        path: "bought",
        component: _179873eb,
        name: "wallet-nfts-bought___cn"
      }, {
        path: "listings",
        component: _e5881d22,
        name: "wallet-nfts-listings___cn"
      }, {
        path: "sets",
        component: _1d4337d8,
        name: "wallet-nfts-sets___cn"
      }, {
        path: "sold",
        component: _18286d58,
        name: "wallet-nfts-sold___cn"
      }, {
        path: "sortingOptions",
        component: _4d489c55,
        name: "wallet-nfts-sortingOptions___cn"
      }, {
        path: "sets/:id",
        component: _2f9e5b7c,
        name: "wallet-nfts-sets-id___cn"
      }, {
        path: "trading/:id?",
        component: _f9f1b108,
        name: "wallet-nfts-trading-id___cn"
      }]
    }, {
      path: "positions",
      component: _3b5e75dc,
      name: "wallet-positions___cn"
    }, {
      path: "resources",
      component: _45b0bd66,
      name: "wallet-resources___cn"
    }, {
      path: "tokens",
      component: _4f912bbc,
      name: "wallet-tokens___cn"
    }]
  }, {
    path: "/cn/wallet-inventory",
    component: _f9de1474,
    children: [{
      path: "",
      component: _4ba38fc9,
      name: "wallet-inventory___cn"
    }, {
      path: "history",
      component: _164a826a,
      name: "wallet-inventory-history___cn"
    }, {
      path: "liquidity_pools",
      component: _37fcb0fe,
      name: "wallet-inventory-liquidity_pools___cn"
    }, {
      path: "nfts",
      component: _3a477293,
      name: "wallet-inventory-nfts___cn"
    }, {
      path: "positions",
      component: _32ccc03e,
      name: "wallet-inventory-positions___cn"
    }, {
      path: "resources",
      component: _3d1f07c8,
      name: "wallet-inventory-resources___cn"
    }, {
      path: "trade-offer",
      component: _3c344fea,
      name: "wallet-inventory-trade-offer___cn"
    }]
  }, {
    path: "/farm/create",
    component: _138f66a7,
    name: "farm-create___en"
  }, {
    path: "/it/about",
    component: _412918bf,
    name: "about___it"
  }, {
    path: "/it/analytics",
    component: _01f9cf38,
    children: [{
      path: "",
      component: _9967e88a,
      name: "analytics___it"
    }, {
      path: "pools",
      component: _ea96ca7a,
      name: "analytics-pools___it"
    }, {
      path: "spots",
      component: _a61cf9cc,
      name: "analytics-spots___it"
    }, {
      path: "wax-tools",
      component: _39db4ba5,
      name: "analytics-wax-tools___it"
    }, {
      path: "pools/:id",
      component: _212071eb,
      name: "analytics-pools-id___it"
    }, {
      path: "tokens/:id?",
      component: _7645812c,
      name: "analytics-tokens-id___it"
    }, {
      path: ":id",
      component: _4972d126,
      name: "analytics-id___it"
    }, {
      path: ":id/:pair",
      component: _93560ee6,
      name: "analytics-id-pair___it"
    }]
  }, {
    path: "/it/bridge",
    component: _16b0bbc7,
    name: "bridge___it"
  }, {
    path: "/it/bridge_new",
    component: _9503e6b0,
    children: [{
      path: "",
      component: _96af15aa,
      name: "bridge_new___it"
    }, {
      path: "history",
      component: _303bd3ad,
      name: "bridge_new-history___it"
    }]
  }, {
    path: "/it/buy-crypto",
    component: _75b430b4,
    name: "buy-crypto___it"
  }, {
    path: "/it/careers",
    component: _69c22b67,
    name: "careers___it"
  }, {
    path: "/it/chart-widget",
    component: _244d85d1,
    name: "chart-widget___it"
  }, {
    path: "/it/docs",
    component: _fbb63a0e,
    name: "docs___it"
  }, {
    path: "/it/farm",
    component: _64657a12,
    name: "farm___it"
  }, {
    path: "/it/fees",
    component: _44529d6b,
    name: "fees___it"
  }, {
    path: "/it/logos",
    component: _350c1fda,
    name: "logos___it"
  }, {
    path: "/it/markets",
    component: _19dcf92c,
    name: "markets___it"
  }, {
    path: "/it/new_market",
    component: _31fbfff9,
    name: "new_market___it"
  }, {
    path: "/it/nft-market",
    component: _254446ee,
    name: "nft-market___it"
  }, {
    path: "/it/otc",
    component: _773a7cd3,
    name: "otc___it"
  }, {
    path: "/it/positions",
    component: _01f92f9f,
    name: "positions___it"
  }, {
    path: "/it/staking",
    component: _3c58db69,
    name: "staking___it"
  }, {
    path: "/it/swap",
    component: _01352c14,
    name: "swap___it"
  }, {
    path: "/it/swap-widget",
    component: _8121de20,
    name: "swap-widget___it"
  }, {
    path: "/it/test",
    component: _0a9501d0,
    name: "test___it"
  }, {
    path: "/it/test-page",
    component: _45db2c9c,
    name: "test-page___it"
  }, {
    path: "/it/trade",
    component: _bd5afdce,
    name: "trade___it",
    children: [{
      path: ":id?",
      component: _564a9a44,
      name: "trade-index-id___it"
    }]
  }, {
    path: "/it/trading",
    component: _948de3d4,
    name: "trading___it"
  }, {
    path: "/it/wallet",
    component: _246ab437,
    children: [{
      path: "",
      component: _0052150c,
      name: "wallet___it"
    }, {
      path: "farms",
      component: _22dc23b6,
      name: "wallet-farms___it"
    }, {
      path: "friends",
      component: _aecb0e86,
      name: "wallet-friends___it"
    }, {
      path: "history",
      component: _69cb7c3c,
      name: "wallet-history___it"
    }, {
      path: "liquidity_pools",
      component: _611a63f2,
      name: "wallet-liquidity_pools___it"
    }, {
      path: "nfts",
      component: _207c4f82,
      children: [{
        path: "",
        component: _951618fc,
        name: "wallet-nfts___it"
      }, {
        path: "auctions",
        component: _2f934d10,
        name: "wallet-nfts-auctions___it"
      }, {
        path: "bought",
        component: _179873eb,
        name: "wallet-nfts-bought___it"
      }, {
        path: "listings",
        component: _e5881d22,
        name: "wallet-nfts-listings___it"
      }, {
        path: "sets",
        component: _1d4337d8,
        name: "wallet-nfts-sets___it"
      }, {
        path: "sold",
        component: _18286d58,
        name: "wallet-nfts-sold___it"
      }, {
        path: "sortingOptions",
        component: _4d489c55,
        name: "wallet-nfts-sortingOptions___it"
      }, {
        path: "sets/:id",
        component: _2f9e5b7c,
        name: "wallet-nfts-sets-id___it"
      }, {
        path: "trading/:id?",
        component: _f9f1b108,
        name: "wallet-nfts-trading-id___it"
      }]
    }, {
      path: "positions",
      component: _3b5e75dc,
      name: "wallet-positions___it"
    }, {
      path: "resources",
      component: _45b0bd66,
      name: "wallet-resources___it"
    }, {
      path: "tokens",
      component: _4f912bbc,
      name: "wallet-tokens___it"
    }]
  }, {
    path: "/it/wallet-inventory",
    component: _f9de1474,
    children: [{
      path: "",
      component: _4ba38fc9,
      name: "wallet-inventory___it"
    }, {
      path: "history",
      component: _164a826a,
      name: "wallet-inventory-history___it"
    }, {
      path: "liquidity_pools",
      component: _37fcb0fe,
      name: "wallet-inventory-liquidity_pools___it"
    }, {
      path: "nfts",
      component: _3a477293,
      name: "wallet-inventory-nfts___it"
    }, {
      path: "positions",
      component: _32ccc03e,
      name: "wallet-inventory-positions___it"
    }, {
      path: "resources",
      component: _3d1f07c8,
      name: "wallet-inventory-resources___it"
    }, {
      path: "trade-offer",
      component: _3c344fea,
      name: "wallet-inventory-trade-offer___it"
    }]
  }, {
    path: "/nft-market/collectionview",
    component: _0a49f357,
    name: "nft-market-collectionview___en"
  }, {
    path: "/nft-market/create",
    component: _7f4c07da,
    name: "nft-market-create___en"
  }, {
    path: "/nft-market/createcollection",
    component: _298eb19e,
    name: "nft-market-createcollection___en"
  }, {
    path: "/nft-market/createnft",
    component: _56acec5c,
    name: "nft-market-createnft___en"
  }, {
    path: "/nft-market/creatingschema",
    component: _6cb6fb2e,
    name: "nft-market-creatingschema___en"
  }, {
    path: "/nft-market/newtemplate",
    component: _24b59d36,
    name: "nft-market-newtemplate___en"
  }, {
    path: "/nft-market/nft-marketplace",
    component: _200b20d6,
    name: "nft-market-nft-marketplace___en",
    children: [{
      path: "auctions",
      component: _008cbe59,
      name: "nft-market-nft-marketplace-auctions___en"
    }, {
      path: "sales",
      component: _2ac4bbda,
      name: "nft-market-nft-marketplace-sales___en"
    }]
  }, {
    path: "/nft-market/nftburnable",
    component: _4c50bf01,
    name: "nft-market-nftburnable___en"
  }, {
    path: "/nft-market/nftexplorer",
    component: _02717497,
    name: "nft-market-nftexplorer___en",
    children: [{
      path: "accounts",
      component: _7b276be4,
      name: "nft-market-nftexplorer-accounts___en"
    }, {
      path: "all",
      component: _61f949ae,
      name: "nft-market-nftexplorer-all___en"
    }, {
      path: "assets",
      component: _d32361aa,
      name: "nft-market-nftexplorer-assets___en"
    }, {
      path: "collections",
      component: _51bc7d1d,
      name: "nft-market-nftexplorer-collections___en"
    }, {
      path: "templates",
      component: _fb3a767e,
      name: "nft-market-nftexplorer-templates___en"
    }]
  }, {
    path: "/nft-market/schematemplate",
    component: _11132e2f,
    name: "nft-market-schematemplate___en"
  }, {
    path: "/nft-market/schemaview",
    component: _5289b54c,
    name: "nft-market-schemaview___en"
  }, {
    path: "/nft-market/tradeoffer",
    component: _43ecac0c,
    name: "nft-market-tradeoffer___en"
  }, {
    path: "/ph/about",
    component: _412918bf,
    name: "about___ph"
  }, {
    path: "/ph/analytics",
    component: _01f9cf38,
    children: [{
      path: "",
      component: _9967e88a,
      name: "analytics___ph"
    }, {
      path: "pools",
      component: _ea96ca7a,
      name: "analytics-pools___ph"
    }, {
      path: "spots",
      component: _a61cf9cc,
      name: "analytics-spots___ph"
    }, {
      path: "wax-tools",
      component: _39db4ba5,
      name: "analytics-wax-tools___ph"
    }, {
      path: "pools/:id",
      component: _212071eb,
      name: "analytics-pools-id___ph"
    }, {
      path: "tokens/:id?",
      component: _7645812c,
      name: "analytics-tokens-id___ph"
    }, {
      path: ":id",
      component: _4972d126,
      name: "analytics-id___ph"
    }, {
      path: ":id/:pair",
      component: _93560ee6,
      name: "analytics-id-pair___ph"
    }]
  }, {
    path: "/ph/bridge",
    component: _16b0bbc7,
    name: "bridge___ph"
  }, {
    path: "/ph/bridge_new",
    component: _9503e6b0,
    children: [{
      path: "",
      component: _96af15aa,
      name: "bridge_new___ph"
    }, {
      path: "history",
      component: _303bd3ad,
      name: "bridge_new-history___ph"
    }]
  }, {
    path: "/ph/buy-crypto",
    component: _75b430b4,
    name: "buy-crypto___ph"
  }, {
    path: "/ph/careers",
    component: _69c22b67,
    name: "careers___ph"
  }, {
    path: "/ph/chart-widget",
    component: _244d85d1,
    name: "chart-widget___ph"
  }, {
    path: "/ph/docs",
    component: _fbb63a0e,
    name: "docs___ph"
  }, {
    path: "/ph/farm",
    component: _64657a12,
    name: "farm___ph"
  }, {
    path: "/ph/fees",
    component: _44529d6b,
    name: "fees___ph"
  }, {
    path: "/ph/logos",
    component: _350c1fda,
    name: "logos___ph"
  }, {
    path: "/ph/markets",
    component: _19dcf92c,
    name: "markets___ph"
  }, {
    path: "/ph/new_market",
    component: _31fbfff9,
    name: "new_market___ph"
  }, {
    path: "/ph/nft-market",
    component: _254446ee,
    name: "nft-market___ph"
  }, {
    path: "/ph/otc",
    component: _773a7cd3,
    name: "otc___ph"
  }, {
    path: "/ph/positions",
    component: _01f92f9f,
    name: "positions___ph"
  }, {
    path: "/ph/staking",
    component: _3c58db69,
    name: "staking___ph"
  }, {
    path: "/ph/swap",
    component: _01352c14,
    name: "swap___ph"
  }, {
    path: "/ph/swap-widget",
    component: _8121de20,
    name: "swap-widget___ph"
  }, {
    path: "/ph/test",
    component: _0a9501d0,
    name: "test___ph"
  }, {
    path: "/ph/test-page",
    component: _45db2c9c,
    name: "test-page___ph"
  }, {
    path: "/ph/trade",
    component: _bd5afdce,
    name: "trade___ph",
    children: [{
      path: ":id?",
      component: _564a9a44,
      name: "trade-index-id___ph"
    }]
  }, {
    path: "/ph/trading",
    component: _948de3d4,
    name: "trading___ph"
  }, {
    path: "/ph/wallet",
    component: _246ab437,
    children: [{
      path: "",
      component: _0052150c,
      name: "wallet___ph"
    }, {
      path: "farms",
      component: _22dc23b6,
      name: "wallet-farms___ph"
    }, {
      path: "friends",
      component: _aecb0e86,
      name: "wallet-friends___ph"
    }, {
      path: "history",
      component: _69cb7c3c,
      name: "wallet-history___ph"
    }, {
      path: "liquidity_pools",
      component: _611a63f2,
      name: "wallet-liquidity_pools___ph"
    }, {
      path: "nfts",
      component: _207c4f82,
      children: [{
        path: "",
        component: _951618fc,
        name: "wallet-nfts___ph"
      }, {
        path: "auctions",
        component: _2f934d10,
        name: "wallet-nfts-auctions___ph"
      }, {
        path: "bought",
        component: _179873eb,
        name: "wallet-nfts-bought___ph"
      }, {
        path: "listings",
        component: _e5881d22,
        name: "wallet-nfts-listings___ph"
      }, {
        path: "sets",
        component: _1d4337d8,
        name: "wallet-nfts-sets___ph"
      }, {
        path: "sold",
        component: _18286d58,
        name: "wallet-nfts-sold___ph"
      }, {
        path: "sortingOptions",
        component: _4d489c55,
        name: "wallet-nfts-sortingOptions___ph"
      }, {
        path: "sets/:id",
        component: _2f9e5b7c,
        name: "wallet-nfts-sets-id___ph"
      }, {
        path: "trading/:id?",
        component: _f9f1b108,
        name: "wallet-nfts-trading-id___ph"
      }]
    }, {
      path: "positions",
      component: _3b5e75dc,
      name: "wallet-positions___ph"
    }, {
      path: "resources",
      component: _45b0bd66,
      name: "wallet-resources___ph"
    }, {
      path: "tokens",
      component: _4f912bbc,
      name: "wallet-tokens___ph"
    }]
  }, {
    path: "/ph/wallet-inventory",
    component: _f9de1474,
    children: [{
      path: "",
      component: _4ba38fc9,
      name: "wallet-inventory___ph"
    }, {
      path: "history",
      component: _164a826a,
      name: "wallet-inventory-history___ph"
    }, {
      path: "liquidity_pools",
      component: _37fcb0fe,
      name: "wallet-inventory-liquidity_pools___ph"
    }, {
      path: "nfts",
      component: _3a477293,
      name: "wallet-inventory-nfts___ph"
    }, {
      path: "positions",
      component: _32ccc03e,
      name: "wallet-inventory-positions___ph"
    }, {
      path: "resources",
      component: _3d1f07c8,
      name: "wallet-inventory-resources___ph"
    }, {
      path: "trade-offer",
      component: _3c344fea,
      name: "wallet-inventory-trade-offer___ph"
    }]
  }, {
    path: "/positions/history",
    component: _6c47c121,
    name: "positions-history___en"
  }, {
    path: "/positions/new",
    component: _5b437370,
    name: "positions-new___en",
    children: [{
      path: "*",
      component: _cef5c900,
      name: "positions-new-index-all___en"
    }]
  }, {
    path: "/ru/about",
    component: _412918bf,
    name: "about___ru"
  }, {
    path: "/ru/analytics",
    component: _01f9cf38,
    children: [{
      path: "",
      component: _9967e88a,
      name: "analytics___ru"
    }, {
      path: "pools",
      component: _ea96ca7a,
      name: "analytics-pools___ru"
    }, {
      path: "spots",
      component: _a61cf9cc,
      name: "analytics-spots___ru"
    }, {
      path: "wax-tools",
      component: _39db4ba5,
      name: "analytics-wax-tools___ru"
    }, {
      path: "pools/:id",
      component: _212071eb,
      name: "analytics-pools-id___ru"
    }, {
      path: "tokens/:id?",
      component: _7645812c,
      name: "analytics-tokens-id___ru"
    }, {
      path: ":id",
      component: _4972d126,
      name: "analytics-id___ru"
    }, {
      path: ":id/:pair",
      component: _93560ee6,
      name: "analytics-id-pair___ru"
    }]
  }, {
    path: "/ru/bridge",
    component: _16b0bbc7,
    name: "bridge___ru"
  }, {
    path: "/ru/bridge_new",
    component: _9503e6b0,
    children: [{
      path: "",
      component: _96af15aa,
      name: "bridge_new___ru"
    }, {
      path: "history",
      component: _303bd3ad,
      name: "bridge_new-history___ru"
    }]
  }, {
    path: "/ru/buy-crypto",
    component: _75b430b4,
    name: "buy-crypto___ru"
  }, {
    path: "/ru/careers",
    component: _69c22b67,
    name: "careers___ru"
  }, {
    path: "/ru/chart-widget",
    component: _244d85d1,
    name: "chart-widget___ru"
  }, {
    path: "/ru/docs",
    component: _fbb63a0e,
    name: "docs___ru"
  }, {
    path: "/ru/farm",
    component: _64657a12,
    name: "farm___ru"
  }, {
    path: "/ru/fees",
    component: _44529d6b,
    name: "fees___ru"
  }, {
    path: "/ru/logos",
    component: _350c1fda,
    name: "logos___ru"
  }, {
    path: "/ru/markets",
    component: _19dcf92c,
    name: "markets___ru"
  }, {
    path: "/ru/new_market",
    component: _31fbfff9,
    name: "new_market___ru"
  }, {
    path: "/ru/nft-market",
    component: _254446ee,
    name: "nft-market___ru"
  }, {
    path: "/ru/otc",
    component: _773a7cd3,
    name: "otc___ru"
  }, {
    path: "/ru/positions",
    component: _01f92f9f,
    name: "positions___ru"
  }, {
    path: "/ru/staking",
    component: _3c58db69,
    name: "staking___ru"
  }, {
    path: "/ru/swap",
    component: _01352c14,
    name: "swap___ru"
  }, {
    path: "/ru/swap-widget",
    component: _8121de20,
    name: "swap-widget___ru"
  }, {
    path: "/ru/test",
    component: _0a9501d0,
    name: "test___ru"
  }, {
    path: "/ru/test-page",
    component: _45db2c9c,
    name: "test-page___ru"
  }, {
    path: "/ru/trade",
    component: _bd5afdce,
    name: "trade___ru",
    children: [{
      path: ":id?",
      component: _564a9a44,
      name: "trade-index-id___ru"
    }]
  }, {
    path: "/ru/trading",
    component: _948de3d4,
    name: "trading___ru"
  }, {
    path: "/ru/wallet",
    component: _246ab437,
    children: [{
      path: "",
      component: _0052150c,
      name: "wallet___ru"
    }, {
      path: "farms",
      component: _22dc23b6,
      name: "wallet-farms___ru"
    }, {
      path: "friends",
      component: _aecb0e86,
      name: "wallet-friends___ru"
    }, {
      path: "history",
      component: _69cb7c3c,
      name: "wallet-history___ru"
    }, {
      path: "liquidity_pools",
      component: _611a63f2,
      name: "wallet-liquidity_pools___ru"
    }, {
      path: "nfts",
      component: _207c4f82,
      children: [{
        path: "",
        component: _951618fc,
        name: "wallet-nfts___ru"
      }, {
        path: "auctions",
        component: _2f934d10,
        name: "wallet-nfts-auctions___ru"
      }, {
        path: "bought",
        component: _179873eb,
        name: "wallet-nfts-bought___ru"
      }, {
        path: "listings",
        component: _e5881d22,
        name: "wallet-nfts-listings___ru"
      }, {
        path: "sets",
        component: _1d4337d8,
        name: "wallet-nfts-sets___ru"
      }, {
        path: "sold",
        component: _18286d58,
        name: "wallet-nfts-sold___ru"
      }, {
        path: "sortingOptions",
        component: _4d489c55,
        name: "wallet-nfts-sortingOptions___ru"
      }, {
        path: "sets/:id",
        component: _2f9e5b7c,
        name: "wallet-nfts-sets-id___ru"
      }, {
        path: "trading/:id?",
        component: _f9f1b108,
        name: "wallet-nfts-trading-id___ru"
      }]
    }, {
      path: "positions",
      component: _3b5e75dc,
      name: "wallet-positions___ru"
    }, {
      path: "resources",
      component: _45b0bd66,
      name: "wallet-resources___ru"
    }, {
      path: "tokens",
      component: _4f912bbc,
      name: "wallet-tokens___ru"
    }]
  }, {
    path: "/ru/wallet-inventory",
    component: _f9de1474,
    children: [{
      path: "",
      component: _4ba38fc9,
      name: "wallet-inventory___ru"
    }, {
      path: "history",
      component: _164a826a,
      name: "wallet-inventory-history___ru"
    }, {
      path: "liquidity_pools",
      component: _37fcb0fe,
      name: "wallet-inventory-liquidity_pools___ru"
    }, {
      path: "nfts",
      component: _3a477293,
      name: "wallet-inventory-nfts___ru"
    }, {
      path: "positions",
      component: _32ccc03e,
      name: "wallet-inventory-positions___ru"
    }, {
      path: "resources",
      component: _3d1f07c8,
      name: "wallet-inventory-resources___ru"
    }, {
      path: "trade-offer",
      component: _3c344fea,
      name: "wallet-inventory-trade-offer___ru"
    }]
  }, {
    path: "/trading/buy-offers",
    component: _75dd3d2d,
    name: "trading-buy-offers___en"
  }, {
    path: "/trading/gift-links",
    component: _3396046a,
    name: "trading-gift-links___en"
  }, {
    path: "/trading/history",
    component: _1bfea25b,
    name: "trading-history___en"
  }, {
    path: "/trading/trade-offers",
    component: _862b85e2,
    name: "trading-trade-offers___en"
  }, {
    path: "/ua/about",
    component: _412918bf,
    name: "about___ua"
  }, {
    path: "/ua/analytics",
    component: _01f9cf38,
    children: [{
      path: "",
      component: _9967e88a,
      name: "analytics___ua"
    }, {
      path: "pools",
      component: _ea96ca7a,
      name: "analytics-pools___ua"
    }, {
      path: "spots",
      component: _a61cf9cc,
      name: "analytics-spots___ua"
    }, {
      path: "wax-tools",
      component: _39db4ba5,
      name: "analytics-wax-tools___ua"
    }, {
      path: "pools/:id",
      component: _212071eb,
      name: "analytics-pools-id___ua"
    }, {
      path: "tokens/:id?",
      component: _7645812c,
      name: "analytics-tokens-id___ua"
    }, {
      path: ":id",
      component: _4972d126,
      name: "analytics-id___ua"
    }, {
      path: ":id/:pair",
      component: _93560ee6,
      name: "analytics-id-pair___ua"
    }]
  }, {
    path: "/ua/bridge",
    component: _16b0bbc7,
    name: "bridge___ua"
  }, {
    path: "/ua/bridge_new",
    component: _9503e6b0,
    children: [{
      path: "",
      component: _96af15aa,
      name: "bridge_new___ua"
    }, {
      path: "history",
      component: _303bd3ad,
      name: "bridge_new-history___ua"
    }]
  }, {
    path: "/ua/buy-crypto",
    component: _75b430b4,
    name: "buy-crypto___ua"
  }, {
    path: "/ua/careers",
    component: _69c22b67,
    name: "careers___ua"
  }, {
    path: "/ua/chart-widget",
    component: _244d85d1,
    name: "chart-widget___ua"
  }, {
    path: "/ua/docs",
    component: _fbb63a0e,
    name: "docs___ua"
  }, {
    path: "/ua/farm",
    component: _64657a12,
    name: "farm___ua"
  }, {
    path: "/ua/fees",
    component: _44529d6b,
    name: "fees___ua"
  }, {
    path: "/ua/logos",
    component: _350c1fda,
    name: "logos___ua"
  }, {
    path: "/ua/markets",
    component: _19dcf92c,
    name: "markets___ua"
  }, {
    path: "/ua/new_market",
    component: _31fbfff9,
    name: "new_market___ua"
  }, {
    path: "/ua/nft-market",
    component: _254446ee,
    name: "nft-market___ua"
  }, {
    path: "/ua/otc",
    component: _773a7cd3,
    name: "otc___ua"
  }, {
    path: "/ua/positions",
    component: _01f92f9f,
    name: "positions___ua"
  }, {
    path: "/ua/staking",
    component: _3c58db69,
    name: "staking___ua"
  }, {
    path: "/ua/swap",
    component: _01352c14,
    name: "swap___ua"
  }, {
    path: "/ua/swap-widget",
    component: _8121de20,
    name: "swap-widget___ua"
  }, {
    path: "/ua/test",
    component: _0a9501d0,
    name: "test___ua"
  }, {
    path: "/ua/test-page",
    component: _45db2c9c,
    name: "test-page___ua"
  }, {
    path: "/ua/trade",
    component: _bd5afdce,
    name: "trade___ua",
    children: [{
      path: ":id?",
      component: _564a9a44,
      name: "trade-index-id___ua"
    }]
  }, {
    path: "/ua/trading",
    component: _948de3d4,
    name: "trading___ua"
  }, {
    path: "/ua/wallet",
    component: _246ab437,
    children: [{
      path: "",
      component: _0052150c,
      name: "wallet___ua"
    }, {
      path: "farms",
      component: _22dc23b6,
      name: "wallet-farms___ua"
    }, {
      path: "friends",
      component: _aecb0e86,
      name: "wallet-friends___ua"
    }, {
      path: "history",
      component: _69cb7c3c,
      name: "wallet-history___ua"
    }, {
      path: "liquidity_pools",
      component: _611a63f2,
      name: "wallet-liquidity_pools___ua"
    }, {
      path: "nfts",
      component: _207c4f82,
      children: [{
        path: "",
        component: _951618fc,
        name: "wallet-nfts___ua"
      }, {
        path: "auctions",
        component: _2f934d10,
        name: "wallet-nfts-auctions___ua"
      }, {
        path: "bought",
        component: _179873eb,
        name: "wallet-nfts-bought___ua"
      }, {
        path: "listings",
        component: _e5881d22,
        name: "wallet-nfts-listings___ua"
      }, {
        path: "sets",
        component: _1d4337d8,
        name: "wallet-nfts-sets___ua"
      }, {
        path: "sold",
        component: _18286d58,
        name: "wallet-nfts-sold___ua"
      }, {
        path: "sortingOptions",
        component: _4d489c55,
        name: "wallet-nfts-sortingOptions___ua"
      }, {
        path: "sets/:id",
        component: _2f9e5b7c,
        name: "wallet-nfts-sets-id___ua"
      }, {
        path: "trading/:id?",
        component: _f9f1b108,
        name: "wallet-nfts-trading-id___ua"
      }]
    }, {
      path: "positions",
      component: _3b5e75dc,
      name: "wallet-positions___ua"
    }, {
      path: "resources",
      component: _45b0bd66,
      name: "wallet-resources___ua"
    }, {
      path: "tokens",
      component: _4f912bbc,
      name: "wallet-tokens___ua"
    }]
  }, {
    path: "/ua/wallet-inventory",
    component: _f9de1474,
    children: [{
      path: "",
      component: _4ba38fc9,
      name: "wallet-inventory___ua"
    }, {
      path: "history",
      component: _164a826a,
      name: "wallet-inventory-history___ua"
    }, {
      path: "liquidity_pools",
      component: _37fcb0fe,
      name: "wallet-inventory-liquidity_pools___ua"
    }, {
      path: "nfts",
      component: _3a477293,
      name: "wallet-inventory-nfts___ua"
    }, {
      path: "positions",
      component: _32ccc03e,
      name: "wallet-inventory-positions___ua"
    }, {
      path: "resources",
      component: _3d1f07c8,
      name: "wallet-inventory-resources___ua"
    }, {
      path: "trade-offer",
      component: _3c344fea,
      name: "wallet-inventory-trade-offer___ua"
    }]
  }, {
    path: "/cn/farm/create",
    component: _138f66a7,
    name: "farm-create___cn"
  }, {
    path: "/cn/nft-market/collectionview",
    component: _0a49f357,
    name: "nft-market-collectionview___cn"
  }, {
    path: "/cn/nft-market/create",
    component: _7f4c07da,
    name: "nft-market-create___cn"
  }, {
    path: "/cn/nft-market/createcollection",
    component: _298eb19e,
    name: "nft-market-createcollection___cn"
  }, {
    path: "/cn/nft-market/createnft",
    component: _56acec5c,
    name: "nft-market-createnft___cn"
  }, {
    path: "/cn/nft-market/creatingschema",
    component: _6cb6fb2e,
    name: "nft-market-creatingschema___cn"
  }, {
    path: "/cn/nft-market/newtemplate",
    component: _24b59d36,
    name: "nft-market-newtemplate___cn"
  }, {
    path: "/cn/nft-market/nft-marketplace",
    component: _200b20d6,
    name: "nft-market-nft-marketplace___cn",
    children: [{
      path: "auctions",
      component: _008cbe59,
      name: "nft-market-nft-marketplace-auctions___cn"
    }, {
      path: "sales",
      component: _2ac4bbda,
      name: "nft-market-nft-marketplace-sales___cn"
    }]
  }, {
    path: "/cn/nft-market/nftburnable",
    component: _4c50bf01,
    name: "nft-market-nftburnable___cn"
  }, {
    path: "/cn/nft-market/nftexplorer",
    component: _02717497,
    name: "nft-market-nftexplorer___cn",
    children: [{
      path: "accounts",
      component: _7b276be4,
      name: "nft-market-nftexplorer-accounts___cn"
    }, {
      path: "all",
      component: _61f949ae,
      name: "nft-market-nftexplorer-all___cn"
    }, {
      path: "assets",
      component: _d32361aa,
      name: "nft-market-nftexplorer-assets___cn"
    }, {
      path: "collections",
      component: _51bc7d1d,
      name: "nft-market-nftexplorer-collections___cn"
    }, {
      path: "templates",
      component: _fb3a767e,
      name: "nft-market-nftexplorer-templates___cn"
    }]
  }, {
    path: "/cn/nft-market/schematemplate",
    component: _11132e2f,
    name: "nft-market-schematemplate___cn"
  }, {
    path: "/cn/nft-market/schemaview",
    component: _5289b54c,
    name: "nft-market-schemaview___cn"
  }, {
    path: "/cn/nft-market/tradeoffer",
    component: _43ecac0c,
    name: "nft-market-tradeoffer___cn"
  }, {
    path: "/cn/positions/history",
    component: _6c47c121,
    name: "positions-history___cn"
  }, {
    path: "/cn/positions/new",
    component: _5b437370,
    name: "positions-new___cn",
    children: [{
      path: "*",
      component: _cef5c900,
      name: "positions-new-index-all___cn"
    }]
  }, {
    path: "/cn/trading/buy-offers",
    component: _75dd3d2d,
    name: "trading-buy-offers___cn"
  }, {
    path: "/cn/trading/gift-links",
    component: _3396046a,
    name: "trading-gift-links___cn"
  }, {
    path: "/cn/trading/history",
    component: _1bfea25b,
    name: "trading-history___cn"
  }, {
    path: "/cn/trading/trade-offers",
    component: _862b85e2,
    name: "trading-trade-offers___cn"
  }, {
    path: "/it/farm/create",
    component: _138f66a7,
    name: "farm-create___it"
  }, {
    path: "/it/nft-market/collectionview",
    component: _0a49f357,
    name: "nft-market-collectionview___it"
  }, {
    path: "/it/nft-market/create",
    component: _7f4c07da,
    name: "nft-market-create___it"
  }, {
    path: "/it/nft-market/createcollection",
    component: _298eb19e,
    name: "nft-market-createcollection___it"
  }, {
    path: "/it/nft-market/createnft",
    component: _56acec5c,
    name: "nft-market-createnft___it"
  }, {
    path: "/it/nft-market/creatingschema",
    component: _6cb6fb2e,
    name: "nft-market-creatingschema___it"
  }, {
    path: "/it/nft-market/newtemplate",
    component: _24b59d36,
    name: "nft-market-newtemplate___it"
  }, {
    path: "/it/nft-market/nft-marketplace",
    component: _200b20d6,
    name: "nft-market-nft-marketplace___it",
    children: [{
      path: "auctions",
      component: _008cbe59,
      name: "nft-market-nft-marketplace-auctions___it"
    }, {
      path: "sales",
      component: _2ac4bbda,
      name: "nft-market-nft-marketplace-sales___it"
    }]
  }, {
    path: "/it/nft-market/nftburnable",
    component: _4c50bf01,
    name: "nft-market-nftburnable___it"
  }, {
    path: "/it/nft-market/nftexplorer",
    component: _02717497,
    name: "nft-market-nftexplorer___it",
    children: [{
      path: "accounts",
      component: _7b276be4,
      name: "nft-market-nftexplorer-accounts___it"
    }, {
      path: "all",
      component: _61f949ae,
      name: "nft-market-nftexplorer-all___it"
    }, {
      path: "assets",
      component: _d32361aa,
      name: "nft-market-nftexplorer-assets___it"
    }, {
      path: "collections",
      component: _51bc7d1d,
      name: "nft-market-nftexplorer-collections___it"
    }, {
      path: "templates",
      component: _fb3a767e,
      name: "nft-market-nftexplorer-templates___it"
    }]
  }, {
    path: "/it/nft-market/schematemplate",
    component: _11132e2f,
    name: "nft-market-schematemplate___it"
  }, {
    path: "/it/nft-market/schemaview",
    component: _5289b54c,
    name: "nft-market-schemaview___it"
  }, {
    path: "/it/nft-market/tradeoffer",
    component: _43ecac0c,
    name: "nft-market-tradeoffer___it"
  }, {
    path: "/it/positions/history",
    component: _6c47c121,
    name: "positions-history___it"
  }, {
    path: "/it/positions/new",
    component: _5b437370,
    name: "positions-new___it",
    children: [{
      path: "*",
      component: _cef5c900,
      name: "positions-new-index-all___it"
    }]
  }, {
    path: "/it/trading/buy-offers",
    component: _75dd3d2d,
    name: "trading-buy-offers___it"
  }, {
    path: "/it/trading/gift-links",
    component: _3396046a,
    name: "trading-gift-links___it"
  }, {
    path: "/it/trading/history",
    component: _1bfea25b,
    name: "trading-history___it"
  }, {
    path: "/it/trading/trade-offers",
    component: _862b85e2,
    name: "trading-trade-offers___it"
  }, {
    path: "/ph/farm/create",
    component: _138f66a7,
    name: "farm-create___ph"
  }, {
    path: "/ph/nft-market/collectionview",
    component: _0a49f357,
    name: "nft-market-collectionview___ph"
  }, {
    path: "/ph/nft-market/create",
    component: _7f4c07da,
    name: "nft-market-create___ph"
  }, {
    path: "/ph/nft-market/createcollection",
    component: _298eb19e,
    name: "nft-market-createcollection___ph"
  }, {
    path: "/ph/nft-market/createnft",
    component: _56acec5c,
    name: "nft-market-createnft___ph"
  }, {
    path: "/ph/nft-market/creatingschema",
    component: _6cb6fb2e,
    name: "nft-market-creatingschema___ph"
  }, {
    path: "/ph/nft-market/newtemplate",
    component: _24b59d36,
    name: "nft-market-newtemplate___ph"
  }, {
    path: "/ph/nft-market/nft-marketplace",
    component: _200b20d6,
    name: "nft-market-nft-marketplace___ph",
    children: [{
      path: "auctions",
      component: _008cbe59,
      name: "nft-market-nft-marketplace-auctions___ph"
    }, {
      path: "sales",
      component: _2ac4bbda,
      name: "nft-market-nft-marketplace-sales___ph"
    }]
  }, {
    path: "/ph/nft-market/nftburnable",
    component: _4c50bf01,
    name: "nft-market-nftburnable___ph"
  }, {
    path: "/ph/nft-market/nftexplorer",
    component: _02717497,
    name: "nft-market-nftexplorer___ph",
    children: [{
      path: "accounts",
      component: _7b276be4,
      name: "nft-market-nftexplorer-accounts___ph"
    }, {
      path: "all",
      component: _61f949ae,
      name: "nft-market-nftexplorer-all___ph"
    }, {
      path: "assets",
      component: _d32361aa,
      name: "nft-market-nftexplorer-assets___ph"
    }, {
      path: "collections",
      component: _51bc7d1d,
      name: "nft-market-nftexplorer-collections___ph"
    }, {
      path: "templates",
      component: _fb3a767e,
      name: "nft-market-nftexplorer-templates___ph"
    }]
  }, {
    path: "/ph/nft-market/schematemplate",
    component: _11132e2f,
    name: "nft-market-schematemplate___ph"
  }, {
    path: "/ph/nft-market/schemaview",
    component: _5289b54c,
    name: "nft-market-schemaview___ph"
  }, {
    path: "/ph/nft-market/tradeoffer",
    component: _43ecac0c,
    name: "nft-market-tradeoffer___ph"
  }, {
    path: "/ph/positions/history",
    component: _6c47c121,
    name: "positions-history___ph"
  }, {
    path: "/ph/positions/new",
    component: _5b437370,
    name: "positions-new___ph",
    children: [{
      path: "*",
      component: _cef5c900,
      name: "positions-new-index-all___ph"
    }]
  }, {
    path: "/ph/trading/buy-offers",
    component: _75dd3d2d,
    name: "trading-buy-offers___ph"
  }, {
    path: "/ph/trading/gift-links",
    component: _3396046a,
    name: "trading-gift-links___ph"
  }, {
    path: "/ph/trading/history",
    component: _1bfea25b,
    name: "trading-history___ph"
  }, {
    path: "/ph/trading/trade-offers",
    component: _862b85e2,
    name: "trading-trade-offers___ph"
  }, {
    path: "/ru/farm/create",
    component: _138f66a7,
    name: "farm-create___ru"
  }, {
    path: "/ru/nft-market/collectionview",
    component: _0a49f357,
    name: "nft-market-collectionview___ru"
  }, {
    path: "/ru/nft-market/create",
    component: _7f4c07da,
    name: "nft-market-create___ru"
  }, {
    path: "/ru/nft-market/createcollection",
    component: _298eb19e,
    name: "nft-market-createcollection___ru"
  }, {
    path: "/ru/nft-market/createnft",
    component: _56acec5c,
    name: "nft-market-createnft___ru"
  }, {
    path: "/ru/nft-market/creatingschema",
    component: _6cb6fb2e,
    name: "nft-market-creatingschema___ru"
  }, {
    path: "/ru/nft-market/newtemplate",
    component: _24b59d36,
    name: "nft-market-newtemplate___ru"
  }, {
    path: "/ru/nft-market/nft-marketplace",
    component: _200b20d6,
    name: "nft-market-nft-marketplace___ru",
    children: [{
      path: "auctions",
      component: _008cbe59,
      name: "nft-market-nft-marketplace-auctions___ru"
    }, {
      path: "sales",
      component: _2ac4bbda,
      name: "nft-market-nft-marketplace-sales___ru"
    }]
  }, {
    path: "/ru/nft-market/nftburnable",
    component: _4c50bf01,
    name: "nft-market-nftburnable___ru"
  }, {
    path: "/ru/nft-market/nftexplorer",
    component: _02717497,
    name: "nft-market-nftexplorer___ru",
    children: [{
      path: "accounts",
      component: _7b276be4,
      name: "nft-market-nftexplorer-accounts___ru"
    }, {
      path: "all",
      component: _61f949ae,
      name: "nft-market-nftexplorer-all___ru"
    }, {
      path: "assets",
      component: _d32361aa,
      name: "nft-market-nftexplorer-assets___ru"
    }, {
      path: "collections",
      component: _51bc7d1d,
      name: "nft-market-nftexplorer-collections___ru"
    }, {
      path: "templates",
      component: _fb3a767e,
      name: "nft-market-nftexplorer-templates___ru"
    }]
  }, {
    path: "/ru/nft-market/schematemplate",
    component: _11132e2f,
    name: "nft-market-schematemplate___ru"
  }, {
    path: "/ru/nft-market/schemaview",
    component: _5289b54c,
    name: "nft-market-schemaview___ru"
  }, {
    path: "/ru/nft-market/tradeoffer",
    component: _43ecac0c,
    name: "nft-market-tradeoffer___ru"
  }, {
    path: "/ru/positions/history",
    component: _6c47c121,
    name: "positions-history___ru"
  }, {
    path: "/ru/positions/new",
    component: _5b437370,
    name: "positions-new___ru",
    children: [{
      path: "*",
      component: _cef5c900,
      name: "positions-new-index-all___ru"
    }]
  }, {
    path: "/ru/trading/buy-offers",
    component: _75dd3d2d,
    name: "trading-buy-offers___ru"
  }, {
    path: "/ru/trading/gift-links",
    component: _3396046a,
    name: "trading-gift-links___ru"
  }, {
    path: "/ru/trading/history",
    component: _1bfea25b,
    name: "trading-history___ru"
  }, {
    path: "/ru/trading/trade-offers",
    component: _862b85e2,
    name: "trading-trade-offers___ru"
  }, {
    path: "/ua/farm/create",
    component: _138f66a7,
    name: "farm-create___ua"
  }, {
    path: "/ua/nft-market/collectionview",
    component: _0a49f357,
    name: "nft-market-collectionview___ua"
  }, {
    path: "/ua/nft-market/create",
    component: _7f4c07da,
    name: "nft-market-create___ua"
  }, {
    path: "/ua/nft-market/createcollection",
    component: _298eb19e,
    name: "nft-market-createcollection___ua"
  }, {
    path: "/ua/nft-market/createnft",
    component: _56acec5c,
    name: "nft-market-createnft___ua"
  }, {
    path: "/ua/nft-market/creatingschema",
    component: _6cb6fb2e,
    name: "nft-market-creatingschema___ua"
  }, {
    path: "/ua/nft-market/newtemplate",
    component: _24b59d36,
    name: "nft-market-newtemplate___ua"
  }, {
    path: "/ua/nft-market/nft-marketplace",
    component: _200b20d6,
    name: "nft-market-nft-marketplace___ua",
    children: [{
      path: "auctions",
      component: _008cbe59,
      name: "nft-market-nft-marketplace-auctions___ua"
    }, {
      path: "sales",
      component: _2ac4bbda,
      name: "nft-market-nft-marketplace-sales___ua"
    }]
  }, {
    path: "/ua/nft-market/nftburnable",
    component: _4c50bf01,
    name: "nft-market-nftburnable___ua"
  }, {
    path: "/ua/nft-market/nftexplorer",
    component: _02717497,
    name: "nft-market-nftexplorer___ua",
    children: [{
      path: "accounts",
      component: _7b276be4,
      name: "nft-market-nftexplorer-accounts___ua"
    }, {
      path: "all",
      component: _61f949ae,
      name: "nft-market-nftexplorer-all___ua"
    }, {
      path: "assets",
      component: _d32361aa,
      name: "nft-market-nftexplorer-assets___ua"
    }, {
      path: "collections",
      component: _51bc7d1d,
      name: "nft-market-nftexplorer-collections___ua"
    }, {
      path: "templates",
      component: _fb3a767e,
      name: "nft-market-nftexplorer-templates___ua"
    }]
  }, {
    path: "/ua/nft-market/schematemplate",
    component: _11132e2f,
    name: "nft-market-schematemplate___ua"
  }, {
    path: "/ua/nft-market/schemaview",
    component: _5289b54c,
    name: "nft-market-schemaview___ua"
  }, {
    path: "/ua/nft-market/tradeoffer",
    component: _43ecac0c,
    name: "nft-market-tradeoffer___ua"
  }, {
    path: "/ua/positions/history",
    component: _6c47c121,
    name: "positions-history___ua"
  }, {
    path: "/ua/positions/new",
    component: _5b437370,
    name: "positions-new___ua",
    children: [{
      path: "*",
      component: _cef5c900,
      name: "positions-new-index-all___ua"
    }]
  }, {
    path: "/ua/trading/buy-offers",
    component: _75dd3d2d,
    name: "trading-buy-offers___ua"
  }, {
    path: "/ua/trading/gift-links",
    component: _3396046a,
    name: "trading-gift-links___ua"
  }, {
    path: "/ua/trading/history",
    component: _1bfea25b,
    name: "trading-history___ua"
  }, {
    path: "/ua/trading/trade-offers",
    component: _862b85e2,
    name: "trading-trade-offers___ua"
  }, {
    path: "/",
    component: _60e0f384,
    name: "index___en"
  }, {
    path: "/cn/nft-market/createcollection/:collection_name",
    component: _b5c30b7e,
    name: "nft-market-createcollection-collection_name___cn"
  }, {
    path: "/cn/nft-market/order/:id",
    component: _4b0cf2d8,
    name: "nft-market-order-id___cn"
  }, {
    path: "/cn/otc/order/:id",
    component: _a9ce6306,
    name: "otc-order-id___cn"
  }, {
    path: "/cn/trading/buy-offers/:id?",
    component: _3c02a6d5,
    name: "trading-buy-offers-id___cn"
  }, {
    path: "/cn/trading/gift-links/:id?",
    component: _79edf6f3,
    name: "trading-gift-links-id___cn"
  }, {
    path: "/cn/trading/history/:id",
    component: _0bf77f83,
    name: "trading-history-id___cn"
  }, {
    path: "/cn/trading/trade-offers/:id?",
    component: _1e9b9192,
    name: "trading-trade-offers-id___cn"
  }, {
    path: "/it/nft-market/createcollection/:collection_name",
    component: _b5c30b7e,
    name: "nft-market-createcollection-collection_name___it"
  }, {
    path: "/it/nft-market/order/:id",
    component: _4b0cf2d8,
    name: "nft-market-order-id___it"
  }, {
    path: "/it/otc/order/:id",
    component: _a9ce6306,
    name: "otc-order-id___it"
  }, {
    path: "/it/trading/buy-offers/:id?",
    component: _3c02a6d5,
    name: "trading-buy-offers-id___it"
  }, {
    path: "/it/trading/gift-links/:id?",
    component: _79edf6f3,
    name: "trading-gift-links-id___it"
  }, {
    path: "/it/trading/history/:id",
    component: _0bf77f83,
    name: "trading-history-id___it"
  }, {
    path: "/it/trading/trade-offers/:id?",
    component: _1e9b9192,
    name: "trading-trade-offers-id___it"
  }, {
    path: "/ph/nft-market/createcollection/:collection_name",
    component: _b5c30b7e,
    name: "nft-market-createcollection-collection_name___ph"
  }, {
    path: "/ph/nft-market/order/:id",
    component: _4b0cf2d8,
    name: "nft-market-order-id___ph"
  }, {
    path: "/ph/otc/order/:id",
    component: _a9ce6306,
    name: "otc-order-id___ph"
  }, {
    path: "/ph/trading/buy-offers/:id?",
    component: _3c02a6d5,
    name: "trading-buy-offers-id___ph"
  }, {
    path: "/ph/trading/gift-links/:id?",
    component: _79edf6f3,
    name: "trading-gift-links-id___ph"
  }, {
    path: "/ph/trading/history/:id",
    component: _0bf77f83,
    name: "trading-history-id___ph"
  }, {
    path: "/ph/trading/trade-offers/:id?",
    component: _1e9b9192,
    name: "trading-trade-offers-id___ph"
  }, {
    path: "/ru/nft-market/createcollection/:collection_name",
    component: _b5c30b7e,
    name: "nft-market-createcollection-collection_name___ru"
  }, {
    path: "/ru/nft-market/order/:id",
    component: _4b0cf2d8,
    name: "nft-market-order-id___ru"
  }, {
    path: "/ru/otc/order/:id",
    component: _a9ce6306,
    name: "otc-order-id___ru"
  }, {
    path: "/ru/trading/buy-offers/:id?",
    component: _3c02a6d5,
    name: "trading-buy-offers-id___ru"
  }, {
    path: "/ru/trading/gift-links/:id?",
    component: _79edf6f3,
    name: "trading-gift-links-id___ru"
  }, {
    path: "/ru/trading/history/:id",
    component: _0bf77f83,
    name: "trading-history-id___ru"
  }, {
    path: "/ru/trading/trade-offers/:id?",
    component: _1e9b9192,
    name: "trading-trade-offers-id___ru"
  }, {
    path: "/ua/nft-market/createcollection/:collection_name",
    component: _b5c30b7e,
    name: "nft-market-createcollection-collection_name___ua"
  }, {
    path: "/ua/nft-market/order/:id",
    component: _4b0cf2d8,
    name: "nft-market-order-id___ua"
  }, {
    path: "/ua/otc/order/:id",
    component: _a9ce6306,
    name: "otc-order-id___ua"
  }, {
    path: "/ua/trading/buy-offers/:id?",
    component: _3c02a6d5,
    name: "trading-buy-offers-id___ua"
  }, {
    path: "/ua/trading/gift-links/:id?",
    component: _79edf6f3,
    name: "trading-gift-links-id___ua"
  }, {
    path: "/ua/trading/history/:id",
    component: _0bf77f83,
    name: "trading-history-id___ua"
  }, {
    path: "/ua/trading/trade-offers/:id?",
    component: _1e9b9192,
    name: "trading-trade-offers-id___ua"
  }, {
    path: "/cn/nft-market/createcollection/:collection_name/createschema",
    component: _5d1b3dfe,
    name: "nft-market-createcollection-collection_name-createschema___cn"
  }, {
    path: "/it/nft-market/createcollection/:collection_name/createschema",
    component: _5d1b3dfe,
    name: "nft-market-createcollection-collection_name-createschema___it"
  }, {
    path: "/ph/nft-market/createcollection/:collection_name/createschema",
    component: _5d1b3dfe,
    name: "nft-market-createcollection-collection_name-createschema___ph"
  }, {
    path: "/ru/nft-market/createcollection/:collection_name/createschema",
    component: _5d1b3dfe,
    name: "nft-market-createcollection-collection_name-createschema___ru"
  }, {
    path: "/ua/nft-market/createcollection/:collection_name/createschema",
    component: _5d1b3dfe,
    name: "nft-market-createcollection-collection_name-createschema___ua"
  }, {
    path: "/cn/nft-market/createcollection/:collection_name/:shema/:schema_name",
    component: _04021da6,
    name: "nft-market-createcollection-collection_name-shema-schema_name___cn"
  }, {
    path: "/it/nft-market/createcollection/:collection_name/:shema/:schema_name",
    component: _04021da6,
    name: "nft-market-createcollection-collection_name-shema-schema_name___it"
  }, {
    path: "/ph/nft-market/createcollection/:collection_name/:shema/:schema_name",
    component: _04021da6,
    name: "nft-market-createcollection-collection_name-shema-schema_name___ph"
  }, {
    path: "/ru/nft-market/createcollection/:collection_name/:shema/:schema_name",
    component: _04021da6,
    name: "nft-market-createcollection-collection_name-shema-schema_name___ru"
  }, {
    path: "/ua/nft-market/createcollection/:collection_name/:shema/:schema_name",
    component: _04021da6,
    name: "nft-market-createcollection-collection_name-shema-schema_name___ua"
  }, {
    path: "/cn/nft-market/createcollection/:collection_name/:shema/:schema_name/createTemplate",
    component: _742c151c,
    name: "nft-market-createcollection-collection_name-shema-schema_name-createTemplate___cn"
  }, {
    path: "/it/nft-market/createcollection/:collection_name/:shema/:schema_name/createTemplate",
    component: _742c151c,
    name: "nft-market-createcollection-collection_name-shema-schema_name-createTemplate___it"
  }, {
    path: "/ph/nft-market/createcollection/:collection_name/:shema/:schema_name/createTemplate",
    component: _742c151c,
    name: "nft-market-createcollection-collection_name-shema-schema_name-createTemplate___ph"
  }, {
    path: "/ru/nft-market/createcollection/:collection_name/:shema/:schema_name/createTemplate",
    component: _742c151c,
    name: "nft-market-createcollection-collection_name-shema-schema_name-createTemplate___ru"
  }, {
    path: "/ua/nft-market/createcollection/:collection_name/:shema/:schema_name/createTemplate",
    component: _742c151c,
    name: "nft-market-createcollection-collection_name-shema-schema_name-createTemplate___ua"
  }, {
    path: "/cn/account/:id?",
    component: _23d2170a,
    name: "account-id___cn",
    children: [{
      path: "nfts",
      component: _1bb6414c,
      name: "account-id-nfts___cn",
      children: [{
        path: "auctions",
        component: _85807aba,
        name: "account-id-nfts-auctions___cn"
      }, {
        path: "bought",
        component: _1a099b84,
        name: "account-id-nfts-bought___cn"
      }, {
        path: "inventory",
        component: _098e7fd9,
        name: "account-id-nfts-inventory___cn"
      }, {
        path: "listings",
        component: _1ae86702,
        name: "account-id-nfts-listings___cn"
      }, {
        path: "sets",
        component: _74787e32,
        name: "account-id-nfts-sets___cn"
      }, {
        path: "sold",
        component: _63800067,
        name: "account-id-nfts-sold___cn"
      }, {
        path: "sets/:setid",
        component: _906b5e8e,
        name: "account-id-nfts-sets-setid___cn"
      }]
    }, {
      path: "tokens",
      component: _da55499c,
      name: "account-id-tokens___cn"
    }, {
      path: "transactions",
      component: _012d8126,
      name: "account-id-transactions___cn"
    }]
  }, {
    path: "/cn/nfts/:asset_id?",
    component: _56c1ba8f,
    name: "nfts-asset_id___cn"
  }, {
    path: "/cn/positions/:id",
    component: _c206e472,
    name: "positions-id___cn"
  }, {
    path: "/cn/sale/:id?",
    component: _a9d243a0,
    name: "sale-id___cn"
  }, {
    path: "/it/account/:id?",
    component: _23d2170a,
    name: "account-id___it",
    children: [{
      path: "nfts",
      component: _1bb6414c,
      name: "account-id-nfts___it",
      children: [{
        path: "auctions",
        component: _85807aba,
        name: "account-id-nfts-auctions___it"
      }, {
        path: "bought",
        component: _1a099b84,
        name: "account-id-nfts-bought___it"
      }, {
        path: "inventory",
        component: _098e7fd9,
        name: "account-id-nfts-inventory___it"
      }, {
        path: "listings",
        component: _1ae86702,
        name: "account-id-nfts-listings___it"
      }, {
        path: "sets",
        component: _74787e32,
        name: "account-id-nfts-sets___it"
      }, {
        path: "sold",
        component: _63800067,
        name: "account-id-nfts-sold___it"
      }, {
        path: "sets/:setid",
        component: _906b5e8e,
        name: "account-id-nfts-sets-setid___it"
      }]
    }, {
      path: "tokens",
      component: _da55499c,
      name: "account-id-tokens___it"
    }, {
      path: "transactions",
      component: _012d8126,
      name: "account-id-transactions___it"
    }]
  }, {
    path: "/it/nfts/:asset_id?",
    component: _56c1ba8f,
    name: "nfts-asset_id___it"
  }, {
    path: "/it/positions/:id",
    component: _c206e472,
    name: "positions-id___it"
  }, {
    path: "/it/sale/:id?",
    component: _a9d243a0,
    name: "sale-id___it"
  }, {
    path: "/nft-market/createcollection/:collection_name",
    component: _b5c30b7e,
    name: "nft-market-createcollection-collection_name___en"
  }, {
    path: "/nft-market/order/:id",
    component: _4b0cf2d8,
    name: "nft-market-order-id___en"
  }, {
    path: "/otc/order/:id",
    component: _a9ce6306,
    name: "otc-order-id___en"
  }, {
    path: "/ph/account/:id?",
    component: _23d2170a,
    name: "account-id___ph",
    children: [{
      path: "nfts",
      component: _1bb6414c,
      name: "account-id-nfts___ph",
      children: [{
        path: "auctions",
        component: _85807aba,
        name: "account-id-nfts-auctions___ph"
      }, {
        path: "bought",
        component: _1a099b84,
        name: "account-id-nfts-bought___ph"
      }, {
        path: "inventory",
        component: _098e7fd9,
        name: "account-id-nfts-inventory___ph"
      }, {
        path: "listings",
        component: _1ae86702,
        name: "account-id-nfts-listings___ph"
      }, {
        path: "sets",
        component: _74787e32,
        name: "account-id-nfts-sets___ph"
      }, {
        path: "sold",
        component: _63800067,
        name: "account-id-nfts-sold___ph"
      }, {
        path: "sets/:setid",
        component: _906b5e8e,
        name: "account-id-nfts-sets-setid___ph"
      }]
    }, {
      path: "tokens",
      component: _da55499c,
      name: "account-id-tokens___ph"
    }, {
      path: "transactions",
      component: _012d8126,
      name: "account-id-transactions___ph"
    }]
  }, {
    path: "/ph/nfts/:asset_id?",
    component: _56c1ba8f,
    name: "nfts-asset_id___ph"
  }, {
    path: "/ph/positions/:id",
    component: _c206e472,
    name: "positions-id___ph"
  }, {
    path: "/ph/sale/:id?",
    component: _a9d243a0,
    name: "sale-id___ph"
  }, {
    path: "/ru/account/:id?",
    component: _23d2170a,
    name: "account-id___ru",
    children: [{
      path: "nfts",
      component: _1bb6414c,
      name: "account-id-nfts___ru",
      children: [{
        path: "auctions",
        component: _85807aba,
        name: "account-id-nfts-auctions___ru"
      }, {
        path: "bought",
        component: _1a099b84,
        name: "account-id-nfts-bought___ru"
      }, {
        path: "inventory",
        component: _098e7fd9,
        name: "account-id-nfts-inventory___ru"
      }, {
        path: "listings",
        component: _1ae86702,
        name: "account-id-nfts-listings___ru"
      }, {
        path: "sets",
        component: _74787e32,
        name: "account-id-nfts-sets___ru"
      }, {
        path: "sold",
        component: _63800067,
        name: "account-id-nfts-sold___ru"
      }, {
        path: "sets/:setid",
        component: _906b5e8e,
        name: "account-id-nfts-sets-setid___ru"
      }]
    }, {
      path: "tokens",
      component: _da55499c,
      name: "account-id-tokens___ru"
    }, {
      path: "transactions",
      component: _012d8126,
      name: "account-id-transactions___ru"
    }]
  }, {
    path: "/ru/nfts/:asset_id?",
    component: _56c1ba8f,
    name: "nfts-asset_id___ru"
  }, {
    path: "/ru/positions/:id",
    component: _c206e472,
    name: "positions-id___ru"
  }, {
    path: "/ru/sale/:id?",
    component: _a9d243a0,
    name: "sale-id___ru"
  }, {
    path: "/trading/buy-offers/:id?",
    component: _3c02a6d5,
    name: "trading-buy-offers-id___en"
  }, {
    path: "/trading/gift-links/:id?",
    component: _79edf6f3,
    name: "trading-gift-links-id___en"
  }, {
    path: "/trading/history/:id",
    component: _0bf77f83,
    name: "trading-history-id___en"
  }, {
    path: "/trading/trade-offers/:id?",
    component: _1e9b9192,
    name: "trading-trade-offers-id___en"
  }, {
    path: "/ua/account/:id?",
    component: _23d2170a,
    name: "account-id___ua",
    children: [{
      path: "nfts",
      component: _1bb6414c,
      name: "account-id-nfts___ua",
      children: [{
        path: "auctions",
        component: _85807aba,
        name: "account-id-nfts-auctions___ua"
      }, {
        path: "bought",
        component: _1a099b84,
        name: "account-id-nfts-bought___ua"
      }, {
        path: "inventory",
        component: _098e7fd9,
        name: "account-id-nfts-inventory___ua"
      }, {
        path: "listings",
        component: _1ae86702,
        name: "account-id-nfts-listings___ua"
      }, {
        path: "sets",
        component: _74787e32,
        name: "account-id-nfts-sets___ua"
      }, {
        path: "sold",
        component: _63800067,
        name: "account-id-nfts-sold___ua"
      }, {
        path: "sets/:setid",
        component: _906b5e8e,
        name: "account-id-nfts-sets-setid___ua"
      }]
    }, {
      path: "tokens",
      component: _da55499c,
      name: "account-id-tokens___ua"
    }, {
      path: "transactions",
      component: _012d8126,
      name: "account-id-transactions___ua"
    }]
  }, {
    path: "/ua/nfts/:asset_id?",
    component: _56c1ba8f,
    name: "nfts-asset_id___ua"
  }, {
    path: "/ua/positions/:id",
    component: _c206e472,
    name: "positions-id___ua"
  }, {
    path: "/ua/sale/:id?",
    component: _a9d243a0,
    name: "sale-id___ua"
  }, {
    path: "/nft-market/createcollection/:collection_name/createschema",
    component: _5d1b3dfe,
    name: "nft-market-createcollection-collection_name-createschema___en"
  }, {
    path: "/nft-market/createcollection/:collection_name/:shema/:schema_name",
    component: _04021da6,
    name: "nft-market-createcollection-collection_name-shema-schema_name___en"
  }, {
    path: "/nft-market/createcollection/:collection_name/:shema/:schema_name/createTemplate",
    component: _742c151c,
    name: "nft-market-createcollection-collection_name-shema-schema_name-createTemplate___en"
  }, {
    path: "/account/:id?",
    component: _23d2170a,
    name: "account-id___en",
    children: [{
      path: "nfts",
      component: _1bb6414c,
      name: "account-id-nfts___en",
      children: [{
        path: "auctions",
        component: _85807aba,
        name: "account-id-nfts-auctions___en"
      }, {
        path: "bought",
        component: _1a099b84,
        name: "account-id-nfts-bought___en"
      }, {
        path: "inventory",
        component: _098e7fd9,
        name: "account-id-nfts-inventory___en"
      }, {
        path: "listings",
        component: _1ae86702,
        name: "account-id-nfts-listings___en"
      }, {
        path: "sets",
        component: _74787e32,
        name: "account-id-nfts-sets___en"
      }, {
        path: "sold",
        component: _63800067,
        name: "account-id-nfts-sold___en"
      }, {
        path: "sets/:setid",
        component: _906b5e8e,
        name: "account-id-nfts-sets-setid___en"
      }]
    }, {
      path: "tokens",
      component: _da55499c,
      name: "account-id-tokens___en"
    }, {
      path: "transactions",
      component: _012d8126,
      name: "account-id-transactions___en"
    }]
  }, {
    path: "/nfts/:asset_id?",
    component: _56c1ba8f,
    name: "nfts-asset_id___en"
  }, {
    path: "/positions/:id",
    component: _c206e472,
    name: "positions-id___en"
  }, {
    path: "/sale/:id?",
    component: _a9d243a0,
    name: "sale-id___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
